@import "../color";
$border-radius-benefit: 14px;

// This should be applied on a bootstrap column such as `col-md-4`
.equal-size-benefit {
    flex-wrap: wrap;
    display: flex;
    transition-property: transform, -webkit-transform;
    transition-duration: 0.1s, 0.1s;

    @include media-breakpoint-up(sm) {
        &:hover {
            transform: translateY(-.3875rem);
        }
    }
}

.benefit-listing-node {
    padding: 0;
    background: white;
    border: none;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
    text-align: left;
    color: black;
    border-radius: $border-radius-benefit;
    width: 100%;
    display: inline-block;
    margin-top: 20px;


    a:hover {
        color: $ma-color; // IE11
        color: var(--ma-custom-color);
    }

    .benefit-tile-picture {
        height: 100px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-top-left-radius: $border-radius-benefit;
        border-top-right-radius: $border-radius-benefit;
        width: 100%;
        min-height: 200px;
        float: none;
    }

    .benefit-tile-caption {
        display: block;
        text-overflow: ellipsis;
        padding: 15px;

        > h4 {
            margin-top: 0;
        }

        > p {
            margin: 0;
        }
    }
}

#sub-categories {
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;

    > .category {
        display: inline-block;
        width: 100%;
    }
}

#sub-categories > .category > div {
    box-sizing: border-box;
    padding: 10px;
    background: #fafafa;
    border: none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    i {
        display: block;
        flex: 0 0 32px;
        font-size: 32px;
        text-align: center;
    }

    > .name {
        margin-left: 10px;
    }
}