.pb-1 { padding-bottom: 1rem; }
.pb-2 { padding-bottom: 2rem; }
.pb-3 { padding-bottom: 3rem; }
.pb-4 { padding-bottom: 4rem; }
.pb-5 { padding-bottom: 5rem; }

.pt-1 { padding-top: 1rem; }
.pt-2 { padding-top: 2rem; }
.pt-3 { padding-top: 3rem; }
.pt-4 { padding-top: 4rem; }
.pt-5 { padding-top: 5rem; }

.pr-1 { padding-right: 1rem; }
.pr-2 { padding-right: 2rem; }
.pr-3 { padding-right: 3rem; }
.pr-4 { padding-right: 4rem; }
.pr-5 { padding-right: 5rem; }

.pl-1 { padding-left: 1rem; }
.pl-2 { padding-left: 2rem; }
.pl-3 { padding-left: 3rem; }
.pl-4 { padding-left: 4rem; }
.pl-5 { padding-left: 5rem; }