

#loading-container {
    overflow: hidden;
    position: absolute;
    height: 40px;
    width: 40px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);

    &.in {
        .loading {
            transform: translate(-50%, -58px);
        }
    }

    .loading {
        transition: all 0.5s ease-in-out;
        bottom: -50px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -0);

        .spinner {
            background: white;
            color: #555;
            font-size: 2rem;
            padding: 2px 10px;
            border-radius: 5px;
        }
    }
}