@import "../color";

.categories-background {
    background-color: white;
    flex: 1 1 auto;
}


.categories-icon {
    display: inline-block;

    .icon {
        display: inline-block;
        font-size: 55px;
        text-align: center;
        vertical-align: middle;
        color: white;
    }

    .icon-container {
        padding: 12px;
        border-radius: 10px;
        box-shadow: 0 4px 7px 2px rgba(50, 50, 50, 0.2);
        margin-right: 10px;
        margin-bottom: 20px;
        margin-top: 20px;
        display: inline-block;
    }
}

@include media-breakpoint-down(md) {
    .categories-icon {
        display: flex;
        justify-content: center;

        & .icon-container {
            margin-right: 0;
            margin-bottom: 5px;
        }


    }

    .category {
        display: flex;
        justify-content: center;

        a {
            text-align: center;
        }
    }
}

.categories-container {
text-align: left;

a {
    cursor: pointer;
}

.categories {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;

    .category {
        a:hover, &:hover {
            color: $ma-color; // IE11
            color: var(--ma-custom-color);
        }

        a {
            color: black;
            font-weight: 600;
        }
    }
}

.category-header {
    h1 {
        color: white;
        .icon {
            font-size: 1.25em;
            padding: 1.25rem;
        }
        .fa-fw {
            width: auto;
        }
    }
    .categories-icon {

        .icon-container {
            display: block;
            width: fit-content;
            margin: 0 auto;
            padding: 0;
        }
    }
}

.bootstrap-select {
    &.form-control {
        margin-bottom: 140px;
    }
}
}