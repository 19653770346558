div.form-item {
    div.password-suggestions {
        transition: all 0.2s ease;
        color: red;
        margin: 0;
        border: none;
        padding: 0;
    }
}

.password-strength {
    display: block;
    float: none;
    margin: 2rem 0;
}

@media (max-width: 768px) {
    .password-confirm {
        float: none;
        width: auto;
    }
}