@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "color";

// Want to remove the border radius from jumpotrons, looked dumb
.jumbotron {
  border-radius: 0;
}

.btn-default {
  background-color: $ma-color; // IE11
  background-color: var(--ma-custom-color);
  color: white;
  &:hover {
    color: white;
  }
}

// This is a hacky workaround with the way Drupal inject "button groups"
.form-actions .btn-default:not(.btn-lg) + .btn-default:not(.btn-lg) {
  margin-left: 10px;
}

.form-control:focus {
  border-color: $ma-color; // IE11
  border-color: var(--ma-color);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(54, 185, 151, 0.25);
}

.dropdown-menu {
  z-index: 999999;
}

// We are using !important because the base class is also using it... :(
.bg-light.navbar-light {
  background-color: white !important;
}

// Makes checkboxes not bold.
label>span {
  font-weight: normal;
}