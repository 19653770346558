.jumbotron {
    background-size: cover;
}

.jumbotron-header {
    background: none;
    margin: 0;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px 0;

    h1 {
        font-size: 5rem;
        font-weight: 300;
        margin: 0rem 0;

        > span {
            display: block;
            margin: 20px 0;
        }

        > i + span {
            margin: 0;
        }
    }

    p {
        font-size: inherit;
        font-weight: inherit;
    }

}

.jumbotron-large {
    padding-top: 125px;
    padding-bottom: 125px;
}

@media (max-width: 767px) {
    .jumbotron-header {
        // padding-top: 8px;
        // padding-bottom: 0;

        h1 {
            font-size: 3rem;
        }

        p {
            font-size: inherit;
            font-weight: inherit;
        }
    }
}