.benefit-node {

    color: black;

    img {
        max-width: 100%;
        height: auto;
    }

    .main-img {
        text-align: center;

        img {
            border-radius: 5px;
            margin-bottom: 15px;
            margin-top: 20px;
            width: 100%;
            max-width: 400px;
            display: inline-block;
        }
    }

    #benefit-map {
        height: 400px;
        max-width: 100%;
    }

    .node-content {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 4px 7px 2px rgba(50, 50, 50, 0.1);
        padding: 40px;
    }

}

@include media-breakpoint-down(md) {
    .row.horizontal-row {
        flex-direction: row;
        flex-wrap: nowrap;
        /* justify-content: space-evenly; */
        overflow-x: scroll;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        padding-bottom: 15px;
        
        .equal-size-benefit {
            min-width: 85vw;
        }
    }

}