@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.banner {
    border-radius: 12px;
    margin-bottom: 25px;
    box-shadow: 0 4px 7px 2px rgba(50, 50, 50, 0.1);
    color: black;
    border: 0;

    img {
        border-radius: 12px;
        max-width: 100%;
        width: 100%;
    }
}

.banner-category {
    padding: 20px 0;
    img {
        max-width: 100%;
    }
}

.banner-benefit > * > .banner-desktop {
    margin-top: 15px;
}

.banner-benefit-mobile, .banner-mobile  {
    display: none;
}

@include media-breakpoint-down(sm) {
    .banner-benefit-desktop, .banner-desktop {
        display: none;
    }

    .banner-benefit-mobile, .banner-mobile {
        display: block;
    }
}