/* Bulma.io */
.hero {
    min-height: 100vh;
    align-items: center;
    flex-direction: row;
    display: flex;
    background-size: cover;

    .container {

        .hero-logo-container {
            .logo {
                img {
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }

    &::after {
        content: '';
        height: 100vh;
        visibility: 100vh;
    }

    &.hero-half {
        min-height: 50vh;
        height: 50vh;
    }
}