
.c-cards-container {
	.c-card {
		margin: 5px;
		padding: 20px;

	    background: #fafafa;
	    border: none;
	    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	    border-radius: 4px;

		h1, h2, h3, h4, h5, h6 {
			margin-top: 0;
		}
	}
}

@media (min-width: 992px) {
    .c-cards-container {
		display: flex;
		flex-wrap: wrap;

		.c-card {
			flex: 1 1 auto;
		}
	}
}