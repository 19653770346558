.btn {
    outline: none;
    &:hover, &:active, &:focus, &:visited {
        outline: none;
        text-decoration: none;
    }
    &.btn-social {
        background-image: none;
        color: #ffffff;
        &.btn-fb {
            background-color: #3B5998;
            border-color: #3B5998;
        }

        &.btn-google-plus {
            background-color: #dd4b39;
            border-color: #dd4b39;
        }

        &.btn-linkedin {
            background-color: #007bb5;
            border-color: #007bb5;
        }
    }
    
}