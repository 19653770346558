.display-none {
    display: none;
}

.display-none-i {
    display: none !important;
}

.display-block {
    display: block;
}

.display-block-i {
    display: block !important;
}

.opacity-0 {
    opacity: 0;
}