@import "../color";

.breadcrumb {
    &.breadcrumb-no-background {
        background: none;
        padding-left: 0;
        padding-right: 0;
    }

    margin-bottom: 0;

    a {
        color: $ma-color; // IE11
        color: var(--ma-custom-color);
        border-bottom: 1px solid $ma-color; // IE11
        border-bottom: 1px solid var(--ma-custom-color);
    }

    .active {
        color: $ma-color; // IE11
        color: var(--ma-custom-color);
    }

    & > li + li:before {
        // Adds > next to breadcrumbs
        content: "\00a0>\00a0";
        color: $ma-color; // IE11
        color: var(--ma-custom-color);
    }
}