.text-white {
    color: white;
    
    &:hover, &:active, &:focus {
        color: white;
    }
}

.text-white-i {
    color: white !important;
    
    &:hover, &:active, &:focus {
        color: white !important;
    }
}

.text-black {
    color: #333;

    &:hover, &:active, &:focus {
        color: #333;
    }
}

.text-grey {
    color:grey;
    &:hover, &:active, &:focus {
        color: #333;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-bold {
    font-weight: bold;
}

.text-pipe {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}