.mb-1 { margin-bottom: 1rem; }
.mb-2 { margin-bottom: 2rem; }
.mb-3 { margin-bottom: 3rem; }
.mb-4 { margin-bottom: 4rem; }
.mb-5 { margin-bottom: 5rem; }

.mt-1 { margin-top: 1rem; }
.mt-2 { margin-top: 2rem; }
.mt-3 { margin-top: 3rem; }
.mt-4 { margin-top: 4rem; }
.mt-5 { margin-top: 5rem; }

.mr-1 { margin-right: 1rem; }
.mr-2 { margin-right: 2rem; }
.mr-3 { margin-right: 3rem; }
.mr-4 { margin-right: 4rem; }
.mr-5 { margin-right: 5rem; }

.ml-1 { margin-left: 1rem; }
.ml-2 { margin-left: 2rem; }
.ml-3 { margin-left: 3rem; }
.ml-4 { margin-left: 4rem; }
.ml-5 { margin-left: 5rem; }