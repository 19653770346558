@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "../color";

.benefit-pill-background-shape {
  background-color: #152E49;
  width: 100%;
  height: 80px;
  clip-path: ellipse(55% 80px at 50% 0%);
  margin-bottom: 40px;
}


.benefit-pill-container {
  width: 100%;
  background-color: #152E49;

  .breadcrumb {
    a, & > li + li:before, .active {
      color: $ma-color; // IE11
      color: var(--ma-color);
      border-bottom-color: $ma-color; // IE11
      border-bottom-color: var(--ma-color);
    }
  }

  .benefit-pill {
    border-radius: 12px;
    background-color: white;
    color: black;

    .right-pill {
      background-color: white;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      padding: 40px;

      h4 {
        font-weight: bold;
        margin-bottom: 20px;
      }
    }

    img {
      width: 100%;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    @include media-breakpoint-down(sm) {
      .right-pill {
        background-color: white;
        border-radius: 0 0 12px 12px;
        padding: 20px;
      }

      img {
        width: 100%;
        border-radius: 12px 12px 0 0;
      }
    }
  }
}
