@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "../color";

.navbar-light {

  // Mobile only
  @include media-breakpoint-down(md) {
    .navbar-toggler {
      color: black;
      border: none;
      font-weight: bold;

      &:focus {
        outline: none;
      }

      .menu-icon {
        display: inline;
        width: 15px;
        padding-bottom: 4px;
      }
    }
  }

  .navbar-right {
    justify-content: flex-end;

    // Icons
    .far, .fas {
      color: $ma-color; // IE11
      color: var(--ma-custom-color);
      fill: $ma-color; // IE11
      fill: var(--ma-custom-color);
    }

    // Links
    a {
      font-size: 1.1rem;
      font-weight: 600;
    }
    .nav-link {
      margin-left: 12px;
      color: black;
    }
    .nav-item {
      vertical-align: middle;
      padding-top: 5px;
      padding-bottom: 5px;

      &.highlight {
        background-color: #F0F2F7;
        border-radius: 10px;
      }
    }

    // This should be in Bootstrap 4, but it's missing???
    .dropdown.open .dropdown-menu {
      display: block;
    }

    // Dropdowns
    .dropdown-toggle::after {
      border-top-color: #405E6C;
      border-bottom-color: #405E6C;
    }
    .dropdown-menu {
      background-color: #fff;
      right: 0;
      left: unset;

      .dropdown-item:active, .dropdown-item.active {
        background-color: $ma-color; // IE11
        background-color: var(--ma-custom-color);
      }
    }

    // Mobile only
    @include media-breakpoint-down(md) {
      &.navbar-collapse {
        padding-left: 0;
        padding-right: 0;
        background-color: #2D4A68;
        z-index: 999999;
        overflow: auto;

        &.show, &.collapsing {
          transition: right 100ms;
        }
      }

      .navbar-nav {
        padding-left: 25px;
        padding-right: 25px;
      }

      .blue {
        background-color: #152E49;
        color: white;
        width: 100%;
        padding: 35px 35px 0 35px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }

      .benefit-pill-background-shape {
        margin-bottom: 0;
        height: 40px;
        clip-path: ellipse(55% 40px at 50% 0%);
      }

      .right {
        align-self: flex-end;
      }

      .close-btn, .close-btn:hover, .close-btn:active, .close-btn:focus {
        color: white;
        background-color: transparent;
        font-weight: bold;
        margin-bottom: 15px;

        .close-icon {
          display: inline;
          width: 15px;
          margin-right: 2px;
          transform: rotate(45deg);
        }
      }

      .all-white {
        filter: brightness(0) invert(1);
        margin-bottom: 5px;
      }

      .nav-item {
        a.nav-link, a.nav-link:focus {
          font-weight: normal;
          color: white;
        }

        &.dropdown.highlight {
          background-color: #152E49;
        }
      }
    }
  }
}

.dropdown-menu {
  border: none;
  line-height: 40px;
  border-radius: 0 0 5px 5px;
}


@include media-breakpoint-down(md) {
  .navbar-collapse {
    position: fixed;
    top: 0; /* adjust to height of navbar */
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 75%;
    height: 100%;
    background-color: white;
    z-index: 100;
  }

  .navbar-collapse.collapsing {
    right: -75%;
    transition: height 0s ease;
  }

  .navbar-collapse.show {
    right: 0;
    transition: right 300ms ease-in-out;
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: right 500ms ease-in-out;
  }
}