@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "color";

:root {
    --ma-color: #{$ma-color};
    --ma-custom-color: var(--ma-color);
}

html {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

body {
    color: #484848;
    background-repeat: no-repeat;
    height: 100%;
    background-color: #F0F2F7;
}

.site-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

// For CKEditor
body.cke_editable {
    padding: 10px;
}

a {
    cursor: pointer;
    outline: none;

    &:hover, &:focus {
        text-decoration: none;
    }
}

table {
    border: solid;
}

.container-fluid {
    &.container-full {
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;
    }
}

.btn {
    &.btn-primary, &.btn-primary:focus {
        border-color: transparent;
    }

    &:hover {
        opacity: 0.7;
    }

    &.fb {
        background-image: none;
        background-color: #3B5998;
        border-color: #3B5998;
    }

    &.google {
        background-image: none;
        background-color: #dd4b39;
        border-color: #dd4b39;
    }

    &.linkedin {
        background-image: none;
        background-color: #007bb5;
        border-color: #007bb5;
    }
}

.admin-nav {
    z-index: 1;
}

.logo {
    position: absolute;
    top: 20px;
    z-index: 10;

    &.logo-landing {
        position: relative;
        top: 0;
    }

    img {
        border: 3px solid #fff;
        width: 150px;
        border-radius: 5px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    }
}

.admin-nav li > a {
    color: #333;
    cursor: pointer;
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 40px;
    font-size: 14px;
    font-weight: 700;
}

.fa-fix {
    font-size: 1.25em;
}

.mug {
    width: 48px;
    height: 48px;
    line-height: 48px;
}

.signin {
    color: #fff;
    text-align: center;
    min-height: 750px;
}

.form-signin {
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
    background-color: #fff;
    color: #333;
    border-radius: 5px;
    margin-top: 40px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 200px;

    .form-control {
        position: relative;
        height: auto;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 10px;
        font-size: 16px;
    }

    input[type="email"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.footer {
    background-color: #061322;
    color: white;
    padding-top: 15px;
    width: 100%;

    a {
        color: $ma-color; // IE11
        color: var(--ma-custom-color);
        font-weight: 600;
    }

    h5 {
        font-weight: normal;
    }

    .footer-content {
        background-color: black;
        padding-top: 10px;
        padding-bottom: 10px;

        a {
            font-weight: normal;
            font-size: smaller;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }

        li {
            float: left;
            padding: 0 10px 0 10px;
            &:last-child {
                padding-right: 0;
            }
            &:first-child {
                padding-left: 0;
            }
        }
    }

    .footer-links {
        display: inline-block;

        .link {
            text-decoration: none;
            color: #777;

            &:hover {
                text-decoration: none;
                color: #777;
            }
        }
    }
}

.main-content {
    flex: 1 1 auto;
    &.margin {
        margin-bottom: 20px;
    }
}

.bootstrap-select {
    margin-bottom: 200px;

    .dropdown-menu {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
        border: none;
        background-color: #fff;
        border-radius: 0 0 5px 5px;

        & > li > a {
            color: #333;
            line-height: 40px;
            border-top: 1px solid #f3f3f3;
        }
    }

    & > .dropdown-toggle {
        height: 50px;
    }

    &.form-control {
        margin-bottom: 40px;
    }
}

.floating-block {
    padding: 3rem;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 7px 2px rgba(50, 50, 50, 0.1);
}

.benefits {
    margin-top: 20px;
}

.after-header {
    margin-top: 20px;
}

.input-group-addon, .input-group-addon:hover {
    color: #fff;
}

.cat-selector .btn, .cat-selector .form-control {
    height: 50px;
}

@media (max-width: 767px) {
    .jumbotron h1 {
        font-size: 32px;
    }

    .mug {
        width: 42px;
    }

    .signin h1 {
        margin-top: 100px;
    }

    .form-signin {
        margin-top: 65px;
    }

    .cat-selector .btn, .cat-selector .form-control {
        margin-bottom: 15px;
    }
}

a {
    transition: color 0.25s;
    &:hover {
        color: $ma-color; // IE11
        color: var(--ma-custom-color);
    }
}


.benefit-type-icon {
    background-color: #DBE1E8;
    color: #346496;

    border-radius: 9999px;
    padding: 14px;
    width: fit-content;

    &.small {
        padding: 7px;
        margin-bottom: 0;
        display: inline-block;
        svg {
            height: 25px;
            width: 25px;
        }
    }

    svg {
        fill: #346496;
        height: 50px;
        width: 50px;
    }
}

.benefit-tile-caption {
    span {
        font-weight: bold;
    }

    .saving {
        border-radius: 9999px;
        background-color: #152E49;
        font-weight: bold;
        color: white;
        text-align: center;
        padding: 6px;
    }

    .row {
        margin-bottom: 15px;
        align-items: center;
    }
}

.benefit-type-banner {
    background-color: white;
    border-radius: 12px;
    margin-bottom: 20px;
    box-shadow: 0 4px 7px 2px rgba(50, 50, 50, 0.1);
    justify-content: center;

    .benefit-type {
        border-left: solid 1px #F2F2F3;
        padding: 30px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            width: 100%;
        }

       .content {
           flex: 1 1 auto;
       }

        &:first-child {
            border-left: none;
        }

        .btn {
            max-width: 120px;
            color: $ma-color; // IE11
            color: var(--ma-color);
            background-color: #152E49;
            font-size: smaller;
            padding: 0.5rem 1.5rem;
        }

        .icon {

            background-color: #DBE1E8;
            color: #346496;

            border-radius: 9999px;
            padding: 14px;
            margin-bottom: 10px;

            svg {
                fill: #346496;
                height: 50px;
                width: 50px;
            }
        }
    }
}

.jumbotron.blue {
    background-color: #152E49;
}

.generic-white-content-box {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 7px 2px rgba(50, 50, 50, 0.1);
    padding: 40px;
}

@include media-breakpoint-down(md) {
    .mobile-only {
        display: block;
    }
}
@include media-breakpoint-up(md) {
    .mobile-only {
        display: none;
    }
}

// Overrides of Drupal inbuilt styling:
.reference-autocomplete {
    padding: 6px;
    border: none;
    border-bottom: 1px #d4d4d4 solid;
    border-collapse: collapse;
}

#autocomplete li:last-child .reference-autocomplete {
    border-bottom: none;
}

#autocomplete {
    border: 1px solid #e5e5e5;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 7px 2px rgba(50, 50, 50, 0.1);
}