.field {
    .field-label {
        text-transform: capitalize;
        margin-bottom: 1rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.1;
    }
    .form-group {
        margin-top: 0;
        margin-bottom: 1rem;
    }
}
